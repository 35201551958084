import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion, AccordionDetails, AccordionSummary, FormControl, Grid, TextField,
} from '@mui/material';

import PropTypes from 'prop-types';

import { memo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { label } from '../../../../../../utils/string';
import NewContent from '../Content';
import styles from '../ContentStyles';

function SubPageContent({
  form, index, nested, limited, types, subpage,
}) {
  const expIndex = `${nested ? `${nested}.` : ''}${index}.${subpage.value}`;

  const [searchParams, setSearchParams] = useSearchParams();
  const [expanded, setExpanded] = useState(Boolean(searchParams.get(expIndex) === 'true'));

  return (
    <Accordion
      square
      disableGutters
      expanded={expanded}
      onChange={(_, isExpanded) => {
        searchParams.set(expIndex, isExpanded);
        setSearchParams(searchParams);
        setExpanded(isExpanded);
      }}
    >
      <AccordionSummary sx={{ padding: 0 }} expandIcon={<ExpandMoreIcon />}>
        <Controller
          control={form.control}
          name={`${nested}[content][${index}][text]`}
          render={({ fieldState, field }) => (
            <FormControl sx={{ width: '100%' }} error={Boolean(fieldState.error)}>
              <TextField
                {...field}
                sx={{ '& .MuiInputBase-root': styles.input[subpage.value] }}
                InputProps={{
                  startAdornment: (
                    subpage.value === 'subPageBullet' ? (
                      <div>
                        •&nbsp;

                      </div>
                    ) : null
                  ),
                }}
                required
                error={Boolean(fieldState.error)}
                label={label`${subpage.name}`}
                helperText={fieldState.error?.message}
                onClick={(event) => {
                  event.stopPropagation();
                  field.onChange(event);
                }}
              />
            </FormControl>
          )}
        />
      </AccordionSummary>
      <Grid
        sx={{ display: 'flex', flexDirection: 'row' }}
        component={FormControl}
        container
        spacing={0}
        marginTop={1}
        marginBottom={1}
      >
        <Grid item xs={12}>
          <AccordionDetails sx={{ padding: 0 }}>
            {expanded && (
            <NewContent
              form={form}
              limited={limited}
              nested={`${nested}[content][${index}]`}
              types={types}
            />
            )}
          </AccordionDetails>
        </Grid>
      </Grid>
    </Accordion>
  );
}

SubPageContent.propTypes = {
  form: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  nested: PropTypes.string.isRequired,
  limited: PropTypes.bool.isRequired,
  subpage: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
};
SubPageContent.defaultProps = {
};

export default memo(SubPageContent);
