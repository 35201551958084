import { useQuery } from '@tanstack/react-query';
import queryString from 'qs';

import config from '../../../config/config.json';
import { DEFAULT_EXCLUDE } from '../../../constants/server';
import responseHandler from '../../../utils/responseHandler';
import authorization from '../../authorization';
import { queryOptions } from '../../config';

const queryFn = async (topicUuid, options) => {
  const query = {
    topicUuid,
    attributeMeta: {
      exclude: DEFAULT_EXCLUDE,
    },
    filterMeta: { topicUuid, parentUuid: { is: 'null' } },
    includeMeta: [
      {
        association: 'limit',
        on: {
          topicUuid,
          literal: '"TopicContent".uuid = "limit"."topicContentUuid"',
        },
      },
      {
        association: 'content',
        separate: false,
      },
      {
        association: 'cases',
        include: [{ association: 'case' }],
      },
      {
        association: 'subtopics',
        include: [{ association: 'subtopic' }],
      },
    ],

    caseFilter: {
      filterMeta: { topicUuid },
      includeMeta: [
        {
          association: 'case',
          include: [{ association: 'topicLimit' }],
        },
      ],
    },
    subTopicFilter: {
      includeMeta: [
        {
          association: 'subtopic',
          include: [{ association: 'topicLimit' }],
        },
      ],
    },
  };
  const res = await fetch(
    `${config.api.url}${config.endpoints.learning}topic-content?${queryString.stringify(query)}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: authorization(),
      },
    },
  );
  const data = await res.json();

  const resp = responseHandler(data, 'Get Topic content:');
  if (options?.onSuccess) {
    setImmediate(() => options.onSuccess(resp));
  }
  return resp;
};
export const useTopicContent = (params, options) => {
  return useQuery({
    ...queryOptions,
    ...options,
    queryKey: ['TopicContent', params, options],
    queryFn: queryFn.bind(null, params, options),
  });
};
export const getTopicContent = queryFn;
