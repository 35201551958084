import lo from 'lodash';
import PropTypes from 'prop-types';
import { memo, useRef } from 'react';
import 'react-quill/dist/quill.snow.css';

import ReactQuill from 'react-quill';

import config from '../../../../../../config/config.json';

function EditorWrapper({ toolbar, field }) {
  const editorRef = useRef(null);
  return (

  // <CKEditor
  //   editor={ClassicEditor}
  //   {...field}
  //   data={field.value}
  // />

    <ReactQuill
      id={field.name}
      {...field}
      theme="snow"
      modules={{
        toolbar: config.quill.init[toolbar],
      }}
      formats={['header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video']}
    />

  // <Editor
  //   apiKey={config.editor.token}
  //   init={{
  //     ...config.editor.init,
  //     toolbar: config.editor.init[toolbar],
  //     isRequired: true,
  //     height: 0,
  //   }}
  //   initialValue={field.value}
  //   onInit={(props, editor) => {
  //     editorRef.current = editor;
  //   }}
  //   onBlur={() => {
  //     field.onChange(editorRef.current.getContent());
  //   }}
  //   onChange={(props) => {
  //     // eslint-disable-next-line react/prop-types
  //     if (props.type === 'change' && props.originalEvent?.type === 'blur') {
  //       field.onChange(editorRef.current.getContent());
  //     }
  //   }}
  // />
  );
}

EditorWrapper.propTypes = {
  toolbar: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
};
EditorWrapper.defaultProps = {
};

export default memo(EditorWrapper, (prevProps, nextProps) => {
  const prevVal = lo.pick(prevProps, ['field.value', 'field.name']);
  const nextVal = lo.pick(nextProps, ['field.value', 'field.name']);

  return lo.isEqual(prevVal, nextVal);
});
